import React from "react";
import { Footer, Navbar } from "../components";
const AboutPage = () => {
  return (
    <>
      <Navbar />
      <div className="container my-3 py-3">
        <h1 className="text-center">About Us</h1>
        <hr />
        <p className="lead text-center">
          At Anime Artistry Unleashed, we are more than just a service provider;
          we are a passionate community of anime enthusiasts, artists, and
          storytellers. We've embarked on a creative journey into the
          captivating world of anime, and we're here to share our love and
          talent with you.
        </p>
        <p className="lead text-center">
          Our Story: Founded by a group of anime aficionados, we set out on a
          mission to bring the magic of anime to life. Our journey began with a
          shared dream - to create a space where anime fans, artists, and
          businesses could come together to celebrate and explore the limitless
          possibilities of this incredible art form.
        </p>

        <h2 className="text-center py-4">Our Products</h2>
        <div className="row">
          <div className="col-md-3 col-sm-6 mb-3 px-3">
            <div className="card h-100">
              <img
                className="card-img-top img-fluid"
                src="https://placewaifu.com/image/1"
                alt=""
                height={160}
              />
              <div className="card-body">
                <h5 className="card-title text-center">Naruto Merchandise</h5>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-3 px-3">
            <div className="card h-100">
              <img
                className="card-img-top img-fluid"
                src="https://placewaifu.com/image/2"
                alt=""
                height={160}
              />
              <div className="card-body">
                <h5 className="card-title text-center">
                  My Hero Academia Collectibles
                </h5>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-3 px-3">
            <div className="card h-100">
              <img
                className="card-img-top img-fluid"
                src="https://placewaifu.com/image/3"
                alt=""
                height={160}
              />
              <div className="card-body">
                <h5 className="card-title text-center">
                  Attack on Titan Merch
                </h5>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-3 px-3">
            <div className="card h-100">
              <img
                className="card-img-top img-fluid"
                src="https://placewaifu.com/image/4"
                alt=""
                height={160}
              />
              <div className="card-body">
                <h5 className="card-title text-center">
                  Sailor Moon Collectibles
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutPage;
