import React from "react";

const Footer = () => {
    return (
        <>
            <footer className='mb-0 text-center'>
                <div className='d-flex align-items-center justify-content-center pb-5'>
                    <div className='col-md-6'></div>
                </div>
            </footer>
        </>
    );
};

export default Footer;
